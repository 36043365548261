.c-mask {
    height: 100%;
    line-height: 1;
    background: rgba(0,0,0,0.4);
    display: inline-block;
    span {
        padding: rem(10) rem(20);
    }
    &--blue {
        color: map-get($colors, white);
        background: map-get($colors, blue);
    }
    &--white {
        color: map-get($colors, blue);
        background: map-get($colors, white);
    }
}

.c-input {
    background: map-get($colors, white) !important;
    width: 100%;
    padding: rem(15);
    font-size: rem(14);
    border: 1px solid #ccc;
    box-sizing: border-box;
}
