.m-footer {
    font-size: rem(14);
    text-align: center;
    color: map-get($colors, lightGray);
    background: map-get($colors, white);
    position: relative;
    margin-top: rem(150);
    .l-content-wrapper {
        max-width: rem(700);
        margin: 0 auto;
    }
    &__links {
        margin-top: rem(20);
        margin-bottom: rem(40);
    }
    &__line {
        background: map-get($colors, yellow);
        border-bottom: 8px solid map-get($colors, blue);
        height: 16px;
        z-index: 9;
    }
    .m-logo {
        width: rem(150);
        display: block;
        margin: 0 auto;
        margin-bottom: rem(40);
    }
}
