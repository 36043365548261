.section-news {
    background: map-get($colors, blue);
    padding-bottom: $pbgeneric * 1.5;
    position: relative;
}

.l-box-news {
    .row {
        display: flex;
        &--first {
            justify-content: center;
            margin-bottom: rem(130);
            width: 100%;
        }
        &--second {
            flex-wrap: wrap;
            width: 100%;

            justify-content: center;
            @include breakpoint($wide) {
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            .m-box-numbers {
                margin-bottom: rem(130);
                &:last-of-type {
                    margin-bottom: 0;
                }
                @include breakpoint($wide) {
                    margin-bottom: 0;
                    height: 100%;
                    max-width: 100%;
                    &.-left {
                        width: 55%;
                    }
                    &.-right {
                        margin-top: rem(80);
                        margin-left: 10%;
                        width: 35%;
                    }
                }
            }
        }
    }
    &__view-all {
        text-align: center;
        margin-top: rem(50);
    }
}

.m-list-all {
    .m-side-content__body {
        margin: 0;
    }
    &__news {
        cursor: pointer;
        padding: rem(40) 0;
        border-bottom: 1px solid map-get($colors, lightGray);
        margin: 0 8%;
        @include breakpoint($tabletUp) {
            margin: 0 rem(100);
        }
        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
        }
        &.is-hidden {
            display: none;
        }
    }
}
