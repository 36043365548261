.generic-padding {
    padding: rem(30) 0 0 0;
    @include breakpoint($tablet) {
        padding: rem(70) 0 0 rem(70);
    }
}

.m-intro-section {
    max-width: rem(600);
    &--large {
        max-width: rem(900);
    }
}

.m-quote {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    &__text {
        padding-left: rem(20);
        border-left: 20px solid #f8f8f8;
    }
    .m-button {
        height: 100%;
        margin-top: rem(30);
    }
}

p+.m-quote {
    margin-top: rem(30);
}
