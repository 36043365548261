
.m-angle {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 0;
    border-width: 12.41vw 0vw 0px 100vw;
    width: 100%;
    border-style: solid;
    border-color: map-get($colors, blue) transparent transparent transparent;
    margin: 0;
    padding: 0;
    transform: translateX(0%) rotate(180deg);
    &--white {
        border-color: map-get($colors, white) transparent transparent transparent;
    }
    &--gray {
        z-index: 2;
        border-color: #F8F8F8 transparent transparent transparent;
    }
    &--darkgray {
        z-index: 1;
        border-color: #D7D7D7 transparent transparent transparent;
        bottom: 0%;
        border-width: 13.9vw 0vw 10px 110vw;
    }
}
