// Fonts
$baseFontSize: 10;

// Mq
$phablet: 414px;
$tablet: 768px;
$tabletUp: 769px;
$desktop: 1024px;
$wide: 1280px;
$large1500: 1500px;
$large1750: 1750px;
$large2000: 2000px;

$pbgeneric: 12.41vw;

// Colors
$colors: (
    white: #fff,
    black: #000,
    yellow: #fae51c,
    blue: #112455,
    darkGray: #4a4a4a,
    lightGray: #b6b6b6
);

// Ease
$ease: cubic-bezier(0.785, 0.135, 0.150, 0.860);
