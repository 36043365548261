.section-1 {
    position: relative;
    padding-bottom: $pbgeneric * 1.5;
    padding-top: rem(80);
    overflow: hidden;
    .m-bg {
        will-change: transform;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: url(../img/bkg-full.jpg) top center no-repeat;
        background-size: cover;
    }

    .airplane-1 {
        width: 40vw;
        height: 40vw;
        position: absolute;
        top: -5%;
        right: -5%;
        z-index: 1;
        pointer-events: none;
        transform: rotateX(180deg) rotateY(180deg);
        svg {
            pointer-events: none;
            width: 100%;
            height: 100%;
            display: block;
            fill: map-get($colors, white);
        }
    }
}


.airplane-servizi {
    width: 30vw;
    height: 30vw;
    position: absolute;
    bottom: 0%;
    left: -2.5%;
    z-index: 0;
    pointer-events: none;
    transform: rotateX(180deg) rotateY(180deg);
    svg {
        pointer-events: none;
        width: 100%;
        height: 100%;
        display: block;
        fill: map-get($colors, white);
    }
}
