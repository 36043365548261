.l-content-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 rem(50);
    @include breakpoint($desktop) {
        padding: 0 rem(100);
    }
}

.is-lock {
    overflow: hidden;
}

.m-site {
    overflow: hidden;
}


.section-contacts, .section-news, .section-services, .section-chi-siamo {
    padding-top: $pbgeneric;
    @include breakpoint($tablet) {
        padding-top: $pbgeneric / 1.5;
    }
}
