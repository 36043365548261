@keyframes lineLoader {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
}

.a-parallax {
    will-change: transform, opacity;
}

.a-title {
    opacity: 0;
    overflow: hidden;
    transform: translateY(50%);
    span {
        display: block;
        transform: translateY(110%);
    }
}
