.m-sidenav {
    position: fixed;
    top: 50%;
    left: 30px;
    transform: translateX(-150%) translateY(-50%);
    z-index: 4;
    transition: transform 1s $ease;
    @include breakpoint($tabletUp) {
        transform: translateX(0%) translateY(-50%);
    }
    nav {
        display: flex;
    }
    li {
        border-radius: 30px 30px 30px 30px;
        overflow: hidden;
        position: relative;
        &+li {
            margin-top: 10px;
        }
        &.is-active, &:hover {
            a {
                &:before {
                    background: map-get($colors, white);
                }
                &:after {
                    transform: translateX(0%);
                }
            }
            span {
                transform: translateY(0%);
                transition-delay: .3s;
            }
        }
    }
    a {
        border-radius: 30px 30px 30px 30px;
        line-height: 1;
        overflow: hidden;
        padding: rem(9) rem(14) rem(7) rem(12);
        font-size: rem(15);
        text-transform: uppercase;
        font-weight: 600;
        text-decoration: none;
        position: relative;
        display: inline-flex;
        align-items: center;
        color: map-get($colors, white);
        span {
            position: relative;
            margin-left: rem(10);
            transform: translateY(210%);
            will-change: transform;
            transition: transform .5s $ease;
            transition-delay: 0s;
            z-index: 2;
        }
        &:after {
            content: "";
            border-radius: 30px 30px 30px 30px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.3);
            transform: translateX(-100%);
            will-change: transform;
            transition: transform .5s $ease;
        }
        &:before {
            content: "";
            display: block;
            width: rem(7);
            height: rem(7);
            border-radius: 100%;
            z-index: 3;
            background: rgba(0,0,0,0.3);
            will-change: background;
            transition: background .3s $ease;
        }
    }
}
