.m-close-content {
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity .3s $ease;
    &.is-active {
        opacity: 1;
        z-index: 8;
    }
}


.m-side-content {
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    right: 0;
    background: map-get($colors, white);
    height: 100%;
    z-index: 9;
    transition: transform .5s $ease;
    transform: translateX(105%);
    will-change: transform;
    width: 90%;
    @include breakpoint($tabletUp) {
        width: 60%;
    }
    &.is-active {
        transform: translateX(0);
    }
    &__title {
        margin-bottom: rem(30);
    }
    &__scroll {
        padding: 0 0 rem(100);
        overflow: auto;
        height: 100%;
    }
    &__icon-close {
        display: block;
        .no-touch &{
            &:hover {
                span {
                    transform: scale(.8);
                }
            }
        }
        span {
            display: block;
            transition: transform .3s;
            font-size: rem(45);
        }
    }
    &__head {
        padding: 0 3% 0 8%;
        display: flex;
        height: rem(100);
        align-items: center;
        justify-content: space-between;
        span {
            font-weight: 700;
            color: map-get($colors, darkGray);
        }
        @include breakpoint($tabletUp) {
            padding: 0 rem(30) 0 rem(100);
        }
    }
    &__head-select {
        display: flex;
        small {
            font-weight: lighter;
        }
    }
    &__body {
        margin: 0 8%;
        @include breakpoint($tabletUp) {
            margin: 0 rem(100);
        }
        .swiper-container {
            width: 100%;
            height: 100%;
        }
        .swiper-slide {
            height: auto;
            box-sizing: border-box;
        }
        p {
            display: block;
            margin-top: rem(30);
            margin-bottom: rem(30);
        }
    }
    &__info {
        border-top: 1px solid map-get($colors, yellow);
        border-bottom: 1px solid map-get($colors, yellow);
        padding: rem(50);
        text-align: center;
        margin: rem(50) 8%;
        @include breakpoint($tabletUp) {
            margin: rem(50) rem(100);
        }
        .h-color-blue {
            display: block;
            margin-bottom: rem(30);
            font-weight: 700;
            font-size: rem(20);
        }
    }
    &__footer {
        display: flex;
        position: relative;
        margin: 0 8%;
        @include breakpoint($tabletUp) {
            margin: 0 rem(100);
        }
        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 150%;
            background: map-get($colors, darkGray);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(20deg);
        }
        a {
            width: 50%;
            text-align: center;
            text-decoration: none;
            font-size: rem(18);
            color: map-get($colors, darkGray);
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: color .3s;
            position: relative;
            &.is-disabled {
                opacity: .1;
                pointer-events: none;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,0);
                }
            }
            span {
                margin: 0 rem(20);
            }
            svg {
                transition: transform .3s;
                display: block;
                width: rem(30);
                height: rem(30);
                transition: all .3s;
                path {
                    fill: map-get($colors, darkGray);
                }
            }
        }
        a:first-of-type {
            .no-touch &{
                &:hover {
                    color: map-get($colors, yellow);
                    svg {
                        transform: translateX(-10px);
                        fill: map-get($colors, yellow);
                    }
                }
            }
        }
        a:last-of-type {
            .no-touch &{
                &:hover {
                    color: map-get($colors, yellow);
                    svg {
                        transform: translateX(10px);
                        fill: map-get($colors, yellow);
                    }
                }
            }
        }
    }
    &__box-img {
        display: inline-block;
        margin: rem(30) 0 rem(50);
        position: relative;
        z-index: 2;
        &:after {
            z-index: -1;
            content: "";
            top: 3rem;
            left: 3rem;
            position: absolute;
            height: 100%;
            width: 100%;
            border: 2px solid #112455;
            border-radius: 0 0 25px 0;
        }
        img {
            display: block;
            width: 100%;
            max-width: 100%;
        }
    }
    &--news {
        z-index: 999;
        .m-side-content__scroll {
            transition: all .3s;
            will-change: transform, opacity;
        }
    }
    &--all-news {
        z-index: 99;
        .m-side-content__scroll {
            transition: all .3s;
            will-change: transform, opacity;
        }
    }
    &.is-loading {
        .m-side-content__scroll {
            transform: translateY(20px);
            opacity: 0;
        }
    }
}


.m-custom-select-news {
    cursor: pointer;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid map-get($colors, lightGray);
    padding-right: rem(35);
    padding-bottom: 1px;
    margin-left: rem(15);
    min-width: rem(100);
    &:after {
        pointer-events: none;
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #4a4a4a;
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    select {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
