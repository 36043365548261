@charset "UTF-8";
/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: March 10, 2017
 */
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,700,800,900");
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform,height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform,height; }

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%; }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent); }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent); }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent); }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent); }

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px; }

.swiper-container-cube, .swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-next + .swiper-slide, .swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello.eot?93259479");
  src: url("../fonts/fontello.eot?93259479#iefix") format("embedded-opentype"), url("../fonts/fontello.woff2?93259479") format("woff2"), url("../fonts/fontello.woff?93259479") format("woff"), url("../fonts/fontello.ttf?93259479") format("truetype"), url("../fonts/fontello.svg?93259479#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'fontello';
        src: url('../fonts/fontello.svg?93259479#fontello') format('svg');
    }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-arrow-down:before {
  content: '\e800'; }

/* '' */
.icon-arrow-next:before {
  content: '\e801'; }

/* '' */
.icon-arrow-prev:before {
  content: '\e802'; }

/* '' */
.icon-arrow-slideshow:before {
  content: '\e803'; }

/* '' */
.icon-clock:before {
  content: '\e804'; }

/* '' */
.icon-close:before {
  content: '\e805'; }

/* '' */
.icon-mail:before {
  content: '\e806'; }

/* '' */
.icon-phone:before {
  content: '\e807'; }

/* '' */
.f-numbers {
  font-size: 5rem;
  font-weight: 700; }
  @media (min-width: 768px) {
    .f-numbers {
      font-weight: 900;
      font-size: 7rem; } }
  @media (min-width: 1024px) {
    .f-numbers {
      font-size: 8rem; } }

.f-h1 {
  font-weight: 700;
  font-size: 3rem; }
  @media (min-width: 768px) {
    .f-h1 {
      font-size: 5rem; } }
  @media (min-width: 1024px) {
    .f-h1 {
      font-size: 6rem; } }

.f-h2 {
  font-weight: 700;
  font-size: 4rem; }
  @media (min-width: 1024px) {
    .f-h2 {
      font-size: 5rem; } }

.f-h3 {
  font-weight: 700;
  font-size: 3rem; }
  @media (min-width: 768px) {
    .f-h3 {
      font-size: 4rem; } }
  .f-h3--small {
    font-weight: 700;
    font-size: 2rem; }
    @media (min-width: 768px) {
      .f-h3--small {
        font-size: 3rem; } }

.f-date {
  font-size: 1.8rem;
  font-weight: 400;
  display: block; }

.f-details {
  font-size: 1.4rem;
  font-weight: 400; }

@keyframes lineLoader {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(-100%); } }

.a-parallax {
  will-change: transform, opacity; }

.a-title {
  opacity: 0;
  overflow: hidden;
  transform: translateY(50%); }
  .a-title span {
    display: block;
    transform: translateY(110%); }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
  font-size: 7px; }
  @media (min-width: 1280px) {
    html {
      font-size: 10px; } }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.3; }

b, strong {
  font-weight: bolder; }

a {
  color: inherit; }

.c-mask {
  height: 100%;
  line-height: 1;
  background: rgba(0, 0, 0, 0.4);
  display: inline-block; }
  .c-mask span {
    padding: 1rem 2rem; }
  .c-mask--blue {
    color: #fff;
    background: #112455; }
  .c-mask--white {
    color: #112455;
    background: #fff; }

.c-input {
  background: #fff !important;
  width: 100%;
  padding: 1.5rem;
  font-size: 1.4rem;
  border: 1px solid #ccc;
  box-sizing: border-box; }

.h-show-from-tablet {
  display: none; }
  @media (min-width: 768px) {
    .h-show-from-tablet {
      display: block; } }

.h-show-from-desktop {
  display: none; }
  @media (min-width: 1024px) {
    .h-show-from-desktop {
      display: block; } }

@media (min-width: 768px) {
  .h-hide-from-tablet {
    display: none; } }

@media (min-width: 1024px) {
  .h-hide-from-desktop {
    display: none; } }

.h-align-center {
  text-align: center; }

.h-color-white {
  color: #fff; }

.h-color-blue {
  color: #112455; }

.h-color-light-gray {
  color: #b6b6b6; }

.h-color-dark-gray {
  color: #4a4a4a; }

.h-mw-600 {
  max-width: 60rem; }

.h-arrow-after {
  position: relative; }
  .h-arrow-after:after {
    pointer-events: none;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid #4a4a4a;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%); }

.l-content-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 5rem; }
  @media (min-width: 1024px) {
    .l-content-wrapper {
      padding: 0 10rem; } }

.is-lock {
  overflow: hidden; }

.m-site {
  overflow: hidden; }

.section-contacts, .section-news, .section-services, .section-chi-siamo {
  padding-top: 12.41vw; }
  @media (min-width: 768px) {
    .section-contacts, .section-news, .section-services, .section-chi-siamo {
      padding-top: 8.27333vw; } }

.section-services {
  background: #f8f8f8;
  padding-bottom: 18.615vw;
  position: relative; }

.section-news {
  background: #112455;
  padding-bottom: 18.615vw;
  position: relative; }

.l-box-news .row {
  display: flex; }
  .l-box-news .row--first {
    justify-content: center;
    margin-bottom: 13rem;
    width: 100%; }
  .l-box-news .row--second {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center; }
    @media (min-width: 1280px) {
      .l-box-news .row--second {
        flex-wrap: nowrap;
        justify-content: space-between; } }
    .l-box-news .row--second .m-box-numbers {
      margin-bottom: 13rem; }
      .l-box-news .row--second .m-box-numbers:last-of-type {
        margin-bottom: 0; }
      @media (min-width: 1280px) {
        .l-box-news .row--second .m-box-numbers {
          margin-bottom: 0;
          height: 100%;
          max-width: 100%; }
          .l-box-news .row--second .m-box-numbers.-left {
            width: 55%; }
          .l-box-news .row--second .m-box-numbers.-right {
            margin-top: 8rem;
            margin-left: 10%;
            width: 35%; } }

.l-box-news__view-all {
  text-align: center;
  margin-top: 5rem; }

.m-list-all .m-side-content__body {
  margin: 0; }

.m-list-all__news {
  cursor: pointer;
  padding: 4rem 0;
  border-bottom: 1px solid #b6b6b6;
  margin: 0 8%; }
  @media (min-width: 769px) {
    .m-list-all__news {
      margin: 0 10rem; } }
  .m-list-all__news:last-of-type {
    border-bottom: 0;
    padding-bottom: 0; }
  .m-list-all__news.is-hidden {
    display: none; }

.l-box-numbers {
  padding: 0 0 10rem 0; }
  @media (min-width: 768px) {
    .l-box-numbers {
      padding: 10rem 0 0 0; } }
  .l-box-numbers .row {
    display: flex; }
    .l-box-numbers .row--first {
      justify-content: center;
      width: 100%;
      margin-bottom: 8.5rem; }
      @media (min-width: 768px) {
        .l-box-numbers .row--first {
          margin-bottom: 13rem; } }
    .l-box-numbers .row--second {
      flex-wrap: wrap;
      width: 100%;
      justify-content: center; }
      @media (min-width: 1280px) {
        .l-box-numbers .row--second {
          flex-wrap: nowrap;
          justify-content: space-between; } }
      .l-box-numbers .row--second .m-box-numbers {
        margin-bottom: 8.5rem; }
        @media (min-width: 768px) {
          .l-box-numbers .row--second .m-box-numbers {
            margin-bottom: 13rem; } }
        .l-box-numbers .row--second .m-box-numbers:last-of-type {
          margin-bottom: 0; }
        @media (min-width: 768px) {
          .l-box-numbers .row--second .m-box-numbers.-right {
            margin-left: 10%; } }
        @media (min-width: 1024px) {
          .l-box-numbers .row--second .m-box-numbers {
            margin-bottom: 0;
            height: 100%;
            max-width: 100%; }
            .l-box-numbers .row--second .m-box-numbers.-left {
              width: 55%; }
            .l-box-numbers .row--second .m-box-numbers.-right {
              margin-top: 8rem;
              width: 35%; } }

.l-box-contacts {
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .l-box-contacts .row {
    margin: 7rem 0; }
    @media (min-width: 768px) {
      .l-box-contacts .row {
        display: flex;
        justify-content: center; } }

.l-contacts-col {
  max-width: 30rem;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem; }
  @media (min-width: 768px) {
    .l-contacts-col {
      margin-bottom: 0;
      width: 33.3%; } }

.m-contacts__box-icon {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .m-contacts__box-icon a {
    position: relative;
    text-decoration: none; }
    .m-contacts__box-icon a:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: currentColor;
      opacity: 0;
      transform: scaleX(0);
      transition: all .3s; }
    .m-contacts__box-icon a:hover:after {
      opacity: 1;
      transform: scaleX(1); }
  .m-contacts__box-icon b {
    display: block;
    text-align: center;
    width: 80%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #fae51c; }
  .m-contacts__box-icon small {
    font-size: 1.4rem; }

.m-icon-contacts {
  width: 100%;
  text-align: center;
  color: #fae51c;
  margin-bottom: 2rem;
  font-size: 4rem; }
  @media (min-width: 768px) {
    .m-icon-contacts {
      font-size: 6rem; } }

.airplane-contacts {
  opacity: .7;
  width: 12rem;
  position: absolute;
  bottom: -5%;
  left: -2%;
  z-index: 1;
  pointer-events: none;
  transform: rotateX(180deg) rotateY(180deg); }
  .airplane-contacts svg {
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: block;
    fill: #fff; }

.section-1 {
  position: relative;
  padding-bottom: 18.615vw;
  padding-top: 8rem;
  overflow: hidden; }
  .section-1 .m-bg {
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url(../img/bkg-full.jpg) top center no-repeat;
    background-size: cover; }
  .section-1 .airplane-1 {
    width: 40vw;
    height: 40vw;
    position: absolute;
    top: -5%;
    right: -5%;
    z-index: 1;
    pointer-events: none;
    transform: rotateX(180deg) rotateY(180deg); }
    .section-1 .airplane-1 svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
      display: block;
      fill: #fff; }

.airplane-servizi {
  width: 30vw;
  height: 30vw;
  position: absolute;
  bottom: 0%;
  left: -2.5%;
  z-index: 0;
  pointer-events: none;
  transform: rotateX(180deg) rotateY(180deg); }
  .airplane-servizi svg {
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: block;
    fill: #fff; }

.section-chi-siamo {
  position: relative;
  padding-bottom: 18.615vw;
  overflow: hidden; }
  .section-chi-siamo .m-slider {
    padding-top: 6rem; }

.m-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #fff; }
  .m-loader-wrapper .m-loader {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20rem;
    height: 20rem;
    background: #112455;
    border-radius: 100%; }
  .m-loader-wrapper .m-loader__animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    height: 20rem; }
    .m-loader-wrapper .m-loader__animation:after {
      content: "";
      display: block;
      border: 0 none;
      border-top: 1px dashed #fff;
      background: none;
      height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%) rotate(-45deg); }
    .m-loader-wrapper .m-loader__animation svg {
      z-index: 2;
      display: block;
      width: 15%;
      position: absolute;
      top: 50%;
      left: 50%;
      animation: fly 2s infinite; }

@keyframes fly {
  0% {
    transform: translate(-340%, 240%) scale(1); }
  50% {
    transform: translate(-50%, -50%) scale(2); }
  100% {
    transform: translate(240%, -340%) scale(1); } }

.swiper-container {
  overflow: visible;
  width: 100%; }
  .swiper-container .swiper-slide {
    width: 100%;
    opacity: 0;
    transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    will-change: transform, opacity; }
    .swiper-container .swiper-slide img {
      transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      will-change: transform; }
  .swiper-container .swiper-slide-active {
    opacity: 1;
    transform: scale(1); }
    .swiper-container .swiper-slide-active .m-slider__text div:nth-child(1) {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 0.3s; }
    .swiper-container .swiper-slide-active .m-slider__text div:nth-child(2) {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 0.6s; }
    .swiper-container .swiper-slide-active .m-slider__text div:nth-child(3) {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 0.9s; }
    .swiper-container .swiper-slide-active .m-slider__text div:nth-child(4) {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 1.2s; }
  .swiper-container .swiper-slide-next, .swiper-container .swiper-slide-prev {
    opacity: .1;
    transition: opacity .3s; }
    .swiper-container .swiper-slide-next img, .swiper-container .swiper-slide-prev img {
      transform: scale(0.9); }
    .swiper-container .swiper-slide-next:hover, .swiper-container .swiper-slide-prev:hover {
      opacity: .3; }

.m-slider {
  position: relative;
  width: 100%;
  padding-top: 15rem;
  margin-bottom: 15rem; }
  .m-slider__wrapper {
    width: 100%;
    position: relative; }
  .m-slider__text {
    position: absolute;
    z-index: 9;
    color: #fff;
    max-width: 80%;
    transform: translate(-10%, -50%); }
    .m-slider__text div {
      line-height: 1;
      padding: 1rem 1.5rem;
      background: rgba(0, 0, 0, 0.4);
      margin-bottom: 0.5rem;
      display: inline-block !important;
      transform: translateY(30px);
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s; }
  .m-slider__border {
    top: 5rem;
    left: 5rem;
    position: absolute;
    height: 100%;
    width: 100%;
    border: 2px solid #fae51c;
    border-radius: 0 0 25px 0; }
    .m-slider__border:after {
      content: "";
      display: block;
      width: 14.8rem;
      position: absolute;
      bottom: 0;
      left: -2px;
      margin-bottom: -22px;
      background: url(../img/frame_shape.svg) top left no-repeat;
      height: 22px;
      width: 149px; }
  .m-slider__img {
    position: relative;
    width: 100%;
    padding-bottom: 50.7%; }
    .m-slider__img img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .m-slider .swiper-pagination-bullet {
    width: 1.2rem;
    height: 1.2rem;
    transition: all .3s; }
    .m-slider .swiper-pagination-bullet:hover {
      opacity: 1;
      background: #fff; }
  .m-slider .swiper-pagination {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: -3rem; }
    .m-slider .swiper-pagination--dark .swiper-pagination-bullet-active {
      background: #112455 !important; }
    .m-slider .swiper-pagination--dark .swiper-pagination-bullet:hover {
      opacity: 1;
      background: #112455 !important; }
  .m-slider .swiper-pagination-bullet-active {
    background: #fff !important; }

.m-header {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 2rem 2rem; }
  .m-header .m-logo {
    display: block;
    width: 20rem; }
  .m-header .m-button {
    position: relative;
    margin-right: -4px;
    height: 100%; }

.m-sidenav {
  position: fixed;
  top: 50%;
  left: 30px;
  transform: translateX(-150%) translateY(-50%);
  z-index: 4;
  transition: transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86); }
  @media (min-width: 769px) {
    .m-sidenav {
      transform: translateX(0%) translateY(-50%); } }
  .m-sidenav nav {
    display: flex; }
  .m-sidenav li {
    border-radius: 30px 30px 30px 30px;
    overflow: hidden;
    position: relative; }
    .m-sidenav li + li {
      margin-top: 10px; }
    .m-sidenav li.is-active a:before, .m-sidenav li:hover a:before {
      background: #fff; }
    .m-sidenav li.is-active a:after, .m-sidenav li:hover a:after {
      transform: translateX(0%); }
    .m-sidenav li.is-active span, .m-sidenav li:hover span {
      transform: translateY(0%);
      transition-delay: .3s; }
  .m-sidenav a {
    border-radius: 30px 30px 30px 30px;
    line-height: 1;
    overflow: hidden;
    padding: 0.9rem 1.4rem 0.7rem 1.2rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    color: #fff; }
    .m-sidenav a span {
      position: relative;
      margin-left: 1rem;
      transform: translateY(210%);
      will-change: transform;
      transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transition-delay: 0s;
      z-index: 2; }
    .m-sidenav a:after {
      content: "";
      border-radius: 30px 30px 30px 30px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      transform: translateX(-100%);
      will-change: transform;
      transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86); }
    .m-sidenav a:before {
      content: "";
      display: block;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 100%;
      z-index: 3;
      background: rgba(0, 0, 0, 0.3);
      will-change: background;
      transition: background 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.m-box-numbers {
  border: 2px solid #fae51c;
  border-radius: 0 0 25px 0;
  max-width: 55.5rem;
  height: 100%;
  position: relative;
  padding: 3rem; }
  @media (min-width: 768px) {
    .m-box-numbers {
      padding: 11rem 5rem 5rem 5rem; } }
  .m-box-numbers:after {
    content: "";
    display: block;
    width: 14.8rem;
    position: absolute;
    bottom: 0;
    left: -2px;
    margin-bottom: -22px;
    background: url(../img/frame_shape.svg) top left no-repeat;
    height: 22px;
    width: 149px; }
  .m-box-numbers__title {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    flex-wrap: wrap;
    width: 100%;
    margin-top: -20%;
    margin-left: -12%;
    margin-bottom: 10%; }
    @media (min-width: 768px) {
      .m-box-numbers__title {
        top: 0;
        left: 0;
        margin: 0;
        position: absolute;
        width: 110%;
        transform: translate(-10%, -50%); } }
    .m-box-numbers__title .f-numbers {
      line-height: 1;
      height: 100%;
      padding: 1rem 2rem;
      background: rgba(0, 0, 0, 0.4); }
    .m-box-numbers__title .f-h2 {
      margin-left: 3rem;
      font-size: 3.5rem; }
      @media (min-width: 768px) {
        .m-box-numbers__title .f-h2 {
          white-space: nowrap;
          margin-left: 4rem; } }

.generic-padding {
  padding: 3rem 0 0 0; }
  @media (min-width: 768px) {
    .generic-padding {
      padding: 7rem 0 0 7rem; } }

.m-intro-section {
  max-width: 60rem; }
  .m-intro-section--large {
    max-width: 90rem; }

.m-quote {
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .m-quote__text {
    padding-left: 2rem;
    border-left: 20px solid #f8f8f8; }
  .m-quote .m-button {
    height: 100%;
    margin-top: 3rem; }

p + .m-quote {
  margin-top: 3rem; }

.m-list-services {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .m-list-services {
      margin-top: 7rem; } }
  .m-list-services li {
    margin-bottom: 13rem;
    width: 100%; }
    @media (min-width: 768px) {
      .m-list-services li {
        margin-bottom: 9rem;
        width: 45%; }
        .m-list-services li:nth-child(even) {
          margin-top: 5rem; } }

.m-list-news {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 13rem; }
  @media (min-width: 768px) {
    .m-list-news {
      margin-top: 7rem; } }
  .m-list-news li {
    width: 100%;
    margin-bottom: 15rem; }
    @media (min-width: 768px) {
      .m-list-news li {
        margin-bottom: 9rem;
        width: 45%; }
        .m-list-news li:nth-child(even) {
          margin-top: 5rem; } }

.m-block-content {
  position: relative;
  width: 100%;
  display: block;
  text-decoration: none; }
  .m-block-content .m-button {
    position: absolute;
    right: 3rem;
    bottom: 0;
    transform: translateY(50%); }
  .m-block-content__date {
    opacity: .7;
    position: absolute;
    top: -7rem;
    font-size: 1.8rem;
    color: #fff; }
  .m-block-content__title {
    display: block;
    margin-top: -5.5rem;
    line-height: 1.1; }
  .m-block-content__shadow {
    background: #0d1b40;
    padding: 2rem;
    position: relative;
    z-index: 1; }
  .m-block-content__shadow-back {
    z-index: 0;
    height: 100%;
    width: 100%;
    bottom: -3rem;
    left: -3rem;
    position: absolute;
    border: 1px solid #4a4a4a;
    border-radius: 0 0 0 25px; }
  .m-block-content--with-img .m-block-content__title {
    margin-top: -4.5rem; }
  .m-block-content--with-img .m-block-content__shadow {
    width: 100%;
    height: 0;
    padding-bottom: 62%;
    background: transparent; }
  .m-block-content--with-img img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86); }
  .no-touch .m-block-content--with-img:hover .m-block-content__box-img {
    transform: scale(0.95); }
    .no-touch .m-block-content--with-img:hover .m-block-content__box-img img {
      transform: scale(1.15); }
  .m-block-content__box-img {
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    overflow: hidden;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute; }

.m-close-content {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86); }
  .m-close-content.is-active {
    opacity: 1;
    z-index: 8; }

.m-side-content {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  height: 100%;
  z-index: 9;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translateX(105%);
  will-change: transform;
  width: 90%; }
  @media (min-width: 769px) {
    .m-side-content {
      width: 60%; } }
  .m-side-content.is-active {
    transform: translateX(0); }
  .m-side-content__title {
    margin-bottom: 3rem; }
  .m-side-content__scroll {
    padding: 0 0 10rem;
    overflow: auto;
    height: 100%; }
  .m-side-content__icon-close {
    display: block; }
    .no-touch .m-side-content__icon-close:hover span {
      transform: scale(0.8); }
    .m-side-content__icon-close span {
      display: block;
      transition: transform .3s;
      font-size: 4.5rem; }
  .m-side-content__head {
    padding: 0 3% 0 8%;
    display: flex;
    height: 10rem;
    align-items: center;
    justify-content: space-between; }
    .m-side-content__head span {
      font-weight: 700;
      color: #4a4a4a; }
    @media (min-width: 769px) {
      .m-side-content__head {
        padding: 0 3rem 0 10rem; } }
  .m-side-content__head-select {
    display: flex; }
    .m-side-content__head-select small {
      font-weight: lighter; }
  .m-side-content__body {
    margin: 0 8%; }
    @media (min-width: 769px) {
      .m-side-content__body {
        margin: 0 10rem; } }
    .m-side-content__body .swiper-container {
      width: 100%;
      height: 100%; }
    .m-side-content__body .swiper-slide {
      height: auto;
      box-sizing: border-box; }
    .m-side-content__body p {
      display: block;
      margin-top: 3rem;
      margin-bottom: 3rem; }
  .m-side-content__info {
    border-top: 1px solid #fae51c;
    border-bottom: 1px solid #fae51c;
    padding: 5rem;
    text-align: center;
    margin: 5rem 8%; }
    @media (min-width: 769px) {
      .m-side-content__info {
        margin: 5rem 10rem; } }
    .m-side-content__info .h-color-blue {
      display: block;
      margin-bottom: 3rem;
      font-weight: 700;
      font-size: 2rem; }
  .m-side-content__footer {
    display: flex;
    position: relative;
    margin: 0 8%; }
    @media (min-width: 769px) {
      .m-side-content__footer {
        margin: 0 10rem; } }
    .m-side-content__footer:after {
      content: "";
      display: block;
      width: 1px;
      height: 150%;
      background: #4a4a4a;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(20deg); }
    .m-side-content__footer a {
      width: 50%;
      text-align: center;
      text-decoration: none;
      font-size: 1.8rem;
      color: #4a4a4a;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color .3s;
      position: relative; }
      .m-side-content__footer a.is-disabled {
        opacity: .1;
        pointer-events: none; }
        .m-side-content__footer a.is-disabled:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent; }
      .m-side-content__footer a span {
        margin: 0 2rem; }
      .m-side-content__footer a svg {
        transition: transform .3s;
        display: block;
        width: 3rem;
        height: 3rem;
        transition: all .3s; }
        .m-side-content__footer a svg path {
          fill: #4a4a4a; }
    .no-touch .m-side-content__footer a:first-of-type:hover {
      color: #fae51c; }
      .no-touch .m-side-content__footer a:first-of-type:hover svg {
        transform: translateX(-10px);
        fill: #fae51c; }
    .no-touch .m-side-content__footer a:last-of-type:hover {
      color: #fae51c; }
      .no-touch .m-side-content__footer a:last-of-type:hover svg {
        transform: translateX(10px);
        fill: #fae51c; }
  .m-side-content__box-img {
    display: inline-block;
    margin: 3rem 0 5rem;
    position: relative;
    z-index: 2; }
    .m-side-content__box-img:after {
      z-index: -1;
      content: "";
      top: 3rem;
      left: 3rem;
      position: absolute;
      height: 100%;
      width: 100%;
      border: 2px solid #112455;
      border-radius: 0 0 25px 0; }
    .m-side-content__box-img img {
      display: block;
      width: 100%;
      max-width: 100%; }
  .m-side-content--news {
    z-index: 999; }
    .m-side-content--news .m-side-content__scroll {
      transition: all .3s;
      will-change: transform, opacity; }
  .m-side-content--all-news {
    z-index: 99; }
    .m-side-content--all-news .m-side-content__scroll {
      transition: all .3s;
      will-change: transform, opacity; }
  .m-side-content.is-loading .m-side-content__scroll {
    transform: translateY(20px);
    opacity: 0; }

.m-custom-select-news {
  cursor: pointer;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #b6b6b6;
  padding-right: 3.5rem;
  padding-bottom: 1px;
  margin-left: 1.5rem;
  min-width: 10rem; }
  .m-custom-select-news:after {
    pointer-events: none;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #4a4a4a;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .m-custom-select-news select {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }

.m-mini-slider {
  position: relative;
  margin: 8rem auto 11rem auto;
  width: 85%; }
  .no-touch .m-mini-slider .swiper-button-next, .no-touch .m-mini-slider .swiper-button-prev {
    transition: opacity .3s; }
    .no-touch .m-mini-slider .swiper-button-next:hover, .no-touch .m-mini-slider .swiper-button-prev:hover {
      opacity: .7; }
  .m-mini-slider .swiper-container {
    overflow: hidden; }
  .m-mini-slider__border {
    top: 3rem;
    left: 3rem;
    position: absolute;
    height: 100%;
    width: 100%;
    border: 2px solid #112455;
    border-radius: 0 0 25px 0; }
  .m-mini-slider__box-arrows {
    z-index: 2;
    position: absolute;
    background: #fae51c;
    width: 7rem;
    height: 7rem;
    border-radius: 100%;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%); }
  .m-mini-slider .swiper-button-prev, .m-mini-slider .swiper-button-next {
    background: none;
    position: absolute;
    width: 80%;
    height: 30%;
    margin: 0; }
  .m-mini-slider .swiper-button-prev {
    top: 20%;
    left: 0; }
  .m-mini-slider .swiper-button-next {
    bottom: 20%;
    right: 0;
    top: auto; }
  .m-mini-slider svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke: .5px; }
    .m-mini-slider svg path {
      fill: #112455; }

.m-form {
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
  color: #4a4a4a; }
  .m-form form, .m-form textarea {
    width: 100%;
    display: block; }
  .m-form select {
    color: #4a4a4a;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    width: 100%;
    height: 100%; }

.m-field {
  margin-bottom: 2rem; }
  .m-field--no-margin {
    margin-bottom: 0.5rem; }
  .m-field--center {
    margin-top: 4rem;
    text-align: center; }

.m-angle {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 0;
  border-width: 12.41vw 0vw 0px 100vw;
  width: 100%;
  border-style: solid;
  border-color: #112455 transparent transparent transparent;
  margin: 0;
  padding: 0;
  transform: translateX(0%) rotate(180deg); }
  .m-angle--white {
    border-color: #fff transparent transparent transparent; }
  .m-angle--gray {
    z-index: 2;
    border-color: #F8F8F8 transparent transparent transparent; }
  .m-angle--darkgray {
    z-index: 1;
    border-color: #D7D7D7 transparent transparent transparent;
    bottom: 0%;
    border-width: 13.9vw 0vw 10px 110vw; }

.m-button {
  display: inline-block;
  padding: 1rem 3.5rem;
  border: 0;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: transparent;
  background: #fff;
  text-decoration: none; }
  .m-button span {
    position: relative;
    z-index: 1; }
  .m-button--yellow {
    color: #112455;
    border: 2px solid #fae51c; }
    .m-button--yellow:after {
      z-index: 0;
      background: #fae51c;
      content: "";
      display: block;
      width: 110%;
      height: 110%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86); }
    .no-touch .m-button--yellow:hover:after {
      opacity: 0;
      transform: translate(-50%, -50%) scaleX(0.8) scaleY(0.7); }

.m-footer {
  font-size: 1.4rem;
  text-align: center;
  color: #b6b6b6;
  background: #fff;
  position: relative;
  margin-top: 15rem; }
  .m-footer .l-content-wrapper {
    max-width: 70rem;
    margin: 0 auto; }
  .m-footer__links {
    margin-top: 2rem;
    margin-bottom: 4rem; }
  .m-footer__line {
    background: #fae51c;
    border-bottom: 8px solid #112455;
    height: 16px;
    z-index: 9; }
  .m-footer .m-logo {
    width: 15rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 4rem; }
