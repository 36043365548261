.m-box-numbers {
    border: 2px solid map-get($colors, yellow);
    border-radius: 0 0 25px 0;
    max-width: rem(555);
    height: 100%;
    position: relative;
    padding: rem(30);
    @include breakpoint($tablet) {
        padding: rem(110) rem(50) rem(50) rem(50);
    }
    &:after {
        content: "";
        display: block;
        width: rem(148);
        position: absolute;
        bottom: 0;
        left: -2px;
        margin-bottom: -22px;
        background: url(../img/frame_shape.svg) top left no-repeat;
        height: 22px;
        width: 149px;
    }
    &__title {
        position: relative;
        display: flex;
        align-items: center;
        color: map-get($colors, white);
        flex-wrap: wrap;
        width: 100%;
        margin-top: -20%;
        margin-left: -12%;
        margin-bottom: 10%;
        @include breakpoint($tablet) {
            top: 0;
            left: 0;
            margin: 0;
            position: absolute;
            width: 110%;
            transform: translate(-10%, -50%);
        }
        .f-numbers {
            line-height: 1;
            height: 100%;
            padding: rem(10) rem(20);
            background: rgba(0,0,0,0.4);
        }
        .f-h2 {
            margin-left: rem(30);
            font-size: rem(35);
            @include breakpoint($tablet) {
                white-space: nowrap;
                margin-left: rem(40);
            }
        }
    }
}
