.l-box-numbers {
    padding: 0 0 rem(100) 0;
    @include breakpoint($tablet) {
        padding: rem(100) 0 0 0;
    }
    .row {
        display: flex;
        &--first {
            justify-content: center;
            width: 100%;
            margin-bottom: rem(85);
            @include breakpoint($tablet) {
                margin-bottom: rem(130);
            }
        }
        &--second {
            flex-wrap: wrap;
            width: 100%;

            justify-content: center;
            @include breakpoint($wide) {
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            .m-box-numbers {
                margin-bottom: rem(85);
                @include breakpoint($tablet) {
                    margin-bottom: rem(130);
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                &.-right {
                    @include breakpoint($tablet) {
                        margin-left: 10%;
                    }
                }
                @include breakpoint($desktop) {
                    margin-bottom: 0;
                    height: 100%;
                    max-width: 100%;
                    &.-left {
                        width: 55%;
                    }
                    &.-right {
                        margin-top: rem(80);
                        width: 35%;
                    }
                }
            }
        }
    }
}
