.m-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: #fff;
    .m-loader {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: rem(200);
        height: rem(200);
        background: map-get($colors, blue);
        border-radius: 100%;
    }
    .m-loader__animation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: rem(200);
        height: rem(200);
        &:after {
            content: "";
            display: block;
            border: 0 none;
            border-top: 1px dashed #fff;
            background: none;
            height: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%,-50%) rotate(-45deg);
        }
        svg {
            z-index: 2;
            display: block;
            width: 15%;
            position: absolute;
            top: 50%;
            left: 50%;
            animation: fly 2s infinite;
        }
    }
}


@keyframes fly {
  0% {
    transform: translate(-340%, 240%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(2);
  }
  100% {
    transform: translate(240%, -340%) scale(1);
  }
}





.swiper-container {
    overflow: visible;
    width: 100%;
    .swiper-slide {
        width: 100%;
        opacity: 0;
        transition: all 1s $ease;
        will-change: transform, opacity;
        img {
            transition: all 1s $ease;
            will-change: transform;
        }
    }
    .swiper-slide-active {
        opacity: 1;
        transform: scale(1);
        .m-slider__text {
            @for $i from 1 to 5 {
                div:nth-child(#{$i}) {
                    transform: translateY(0);
                    opacity: 1;
                    transition-delay: $i * .3s;
                }
            }
        }
    }
    .swiper-slide-next, .swiper-slide-prev {
        opacity: .1;
        transition: opacity .3s;
        img {
            transform: scale(.9);
        }
        &:hover {
            opacity: .3;
        }
    }
}

.m-slider {
    position: relative;
    width: 100%;
    padding-top: rem(150);
    margin-bottom: rem(150);
    &__wrapper {
        width: 100%;
        position: relative;
    }
    &__text {
        position: absolute;
        z-index: 9;
        color: #fff;
        max-width: 80%;
        transform: translate(-10%, -50%);
        div {
            line-height: 1;
            padding: rem(10) rem(15);
            background: rgba(0,0,0,0.4);
            margin-bottom: rem(5);
            display: inline-block !important;
            transform: translateY(30px);
            opacity: 0;
            transition: all .5s $ease .3s;
        }
    }
    &__border {
        top: rem(50);
        left: rem(50);
        position: absolute;
        height: 100%;
        width: 100%;
        border: 2px solid map-get($colors, yellow);
        border-radius: 0 0 25px 0;
        &:after {
            content: "";
            display: block;
            width: rem(148);
            position: absolute;
            bottom: 0;
            left: -2px;
            margin-bottom: -22px;
            background: url(../img/frame_shape.svg) top left no-repeat;
            height: 22px;
            width: 149px;
        }
    }
    &__img {
        position: relative;
        width: 100%;
        padding-bottom: 50.7%;
        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .swiper-pagination-bullet {
        width: rem(12);
        height: rem(12);
        transition: all .3s;
        &:hover {
            opacity: 1;
            background: #fff;
        }
    }
    .swiper-pagination {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        bottom: - rem(30);
        &--dark {
            .swiper-pagination-bullet-active {
                background: map-get($colors, blue) !important;
            }
            .swiper-pagination-bullet {
                &:hover {
                    opacity: 1;
                    background: map-get($colors, blue) !important;
                }
            }
        }
    }

    .swiper-pagination-bullet-active {
        background: map-get($colors, white) !important;
    }
}
