.m-header {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: rem(20) 0 rem(20) rem(20);
    .m-logo {
        display: block;
        width: rem(200);
    }
    .m-button {
        position: relative;
        margin-right: -4px;
        height: 100%;
    }
}
