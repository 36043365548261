.m-form {
    max-width: rem(500);
    width: 100%;
    margin: 0 auto;
    margin-top: rem(40);
    color: map-get($colors, darkGray);
    form, textarea {
        width: 100%;
        display: block;
    }
    select {
        color: map-get($colors, darkGray);
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        width: 100%;
        height: 100%;
    }
}

.m-field {
    margin-bottom: rem(20);
    &--no-margin {
        margin-bottom: rem(5);
    }
    &--center {
        margin-top: rem(40);
        text-align: center;
    }
}
