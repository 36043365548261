.m-mini-slider {
    position: relative;
    margin: rem(80) auto rem(110) auto;
    width: 85%;
    .swiper-button-next, .swiper-button-prev {
        .no-touch &{
            transition: opacity .3s;
            &:hover {
                opacity: .7;
            }
        }
    }
    .swiper-container {
        overflow: hidden;
    }
    &__border {
        top: rem(30);
        left: rem(30);
        position: absolute;
        height: 100%;
        width: 100%;
        border: 2px solid map-get($colors, blue);
        border-radius: 0 0 25px 0;
    }
    &__box-arrows {
        z-index: 2;
        position: absolute;
        background: map-get($colors, yellow);
        width: rem(70);
        height: rem(70);
        border-radius: 100%;
        top: 50%;
        left: 0;
        transform: translate(-50%,-50%);
    }
    .swiper-button-prev, .swiper-button-next {
        background: none;
        position: absolute;
        width: 80%;
        height: 30%;
        margin: 0;
    }
    .swiper-button-prev {
        top: 20%;
        left: 0;
    }
    .swiper-button-next {
        bottom: 20%;
        right: 0;
        top: auto;
    }
    svg {
        display: block;
        width: 100%;
        height: 100%;
        stroke: .5px;
        path {
            fill: map-get($colors, blue);
        }
    }
}
