.m-button {
    display: inline-block;
    padding: rem(10) rem(35);
    border: 0;
    font-size: rem(18);
    font-weight: 700;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: transparent;
    background: map-get($colors, white);
    text-decoration: none;
    span {
        position: relative;
        z-index: 1;
    }
    &--yellow {
        color: map-get($colors, blue);
        border: 2px solid map-get($colors, yellow);
        &:after {
            z-index: 0;
            background: map-get($colors, yellow);
            content: "";
            display: block;
            width: 110%;
            height: 110%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all .5s $ease;
        }
        .no-touch &{
            &:hover {
                &:after {
                    opacity: 0;
                    transform: translate(-50%, -50%) scaleX(.8) scaleY(.7);
                }
            }
        }
    }
}
