.m-list-services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: rem(50);
    @include breakpoint($tablet) {
        margin-top: rem(70);
    }
    li {
        margin-bottom: rem(130);
        width: 100%;
        @include breakpoint($tablet) {
            margin-bottom: rem(90);
            width: 45%;
            &:nth-child(even) {
                margin-top: rem(50);
            }
        }
    }
}


.m-list-news {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: rem(130);
    @include breakpoint($tablet) {
        margin-top: rem(70);
    }
    li {
        width: 100%;
        margin-bottom: rem(150);
        @include breakpoint($tablet) {
            margin-bottom: rem(90);
            width: 45%;
            &:nth-child(even) {
                margin-top: rem(50);
            }
        }
    }
}


.m-block-content {
    position: relative;
    width: 100%;
    display: block;
    text-decoration: none;
    .m-button {
        position: absolute;
        right: rem(30);
        bottom: 0;
        transform: translateY(50%);
    }
    &__date {
        opacity: .7;
        position: absolute;
        top: - rem(70);
        font-size: rem(18);
        color: map-get($colors, white);
    }
    &__title {
        display: block;
        margin-top: - rem(55);
        line-height: 1.1;
    }
    &__shadow {
        background: darken(map-get($colors, blue), 5);
        padding: rem(20);
        position: relative;
        z-index: 1;
    }
    &__shadow-back {
        z-index: 0;
        height: 100%;
        width: 100%;
        bottom: - rem(30);
        left: - rem(30);
        position: absolute;
        border: 1px solid map-get($colors, darkGray);
        border-radius: 0 0 0 25px;
    }
    &--with-img {
        .m-block-content__title {
            margin-top: - rem(45);
        }
        .m-block-content__shadow {
            width: 100%;
            height: 0;
            padding-bottom: 62%;
            background: transparent;
        }
        img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
            transition: transform .5s $ease;
        }
        .no-touch & {
            &:hover {
                .m-block-content__box-img {
                    transform: scale(.95);
                    img {
                        transform: scale(1.15);
                    }
                }
            }
        }
    }
    &__box-img {
        transition: transform .5s $ease;
        overflow: hidden;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
