.h-show-from-tablet {
    display: none;
    @include breakpoint($tablet) {
        display: block;
    }
}

.h-show-from-desktop {
    display: none;
    @include breakpoint($desktop) {
        display: block;
    }
}

.h-hide-from-tablet {
    @include breakpoint($tablet) {
        display: none;
    }
}

.h-hide-from-desktop {
    @include breakpoint($desktop) {
        display: none;
    }
}

.h-align-center {
    text-align: center;
}

.h-color-white {
    color: map-get($colors, white);
}

.h-color-blue {
    color: map-get($colors, blue);
}

.h-color-light-gray {
    color: map-get($colors, lightGray);
}

.h-color-dark-gray {
    color: map-get($colors, darkGray);
}

.h-mw-600 {
    max-width: rem(600);
}

.h-arrow-after {
    position: relative;
    &:after {
        pointer-events: none;
        content: "";
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid map-get($colors, darkGray);
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}
