.l-box-contacts {
    background: map-get($colors, white);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .row {
        margin: rem(70) 0;
        @include breakpoint($tablet) {
            display: flex;
            justify-content: center;
        }
    }
}


.l-contacts-col {
    max-width: rem(300);
    width: 100%;
    margin: 0 auto;
    margin-bottom: rem(40);
    @include breakpoint($tablet) {
        margin-bottom: 0;
        width: 33.3%;
    }
}

.m-contacts__box-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
        position: relative;
        text-decoration: none;
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: currentColor;
            opacity: 0;
            transform: scaleX(0);
            transition: all .3s;
        }
        &:hover {
            &:after {
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }
    b {
        display: block;
        text-align: center;
        width: 80%;
        padding-bottom: rem(10);
        margin-bottom: rem(10);
        border-bottom: 1px solid map-get($colors, yellow);
    }
    small {
        font-size: rem(14);
    }
}

.m-icon-contacts {
    width: 100%;
    text-align: center;
    color: map-get($colors, yellow);
    margin-bottom: rem(20);
    font-size: rem(40);
    @include breakpoint($tablet) {
        font-size: rem(60);
    }
}


.airplane-contacts {
    opacity: .7;
    width: rem(120);
    position: absolute;
    bottom: -5%;
    left: -2%;
    z-index: 1;
    pointer-events: none;
    transform: rotateX(180deg) rotateY(180deg);
    svg {
        pointer-events: none;
        width: 100%;
        height: 100%;
        display: block;
        fill: map-get($colors, white);
    }
}
