@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700,800,900');

$baseFont: 'Raleway', sans-serif;

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?93259479');
    src: url('../fonts/fontello.eot?93259479#iefix') format('embedded-opentype'),
         url('../fonts/fontello.woff2?93259479') format('woff2'),
         url('../fonts/fontello.woff?93259479') format('woff'),
         url('../fonts/fontello.ttf?93259479') format('truetype'),
         url('../fonts/fontello.svg?93259479#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'fontello';
        src: url('../fonts/fontello.svg?93259479#fontello') format('svg');
    }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    // width: 1em;
    // margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    // margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow-down:before { content: '\e800'; } /* '' */
.icon-arrow-next:before { content: '\e801'; } /* '' */
.icon-arrow-prev:before { content: '\e802'; } /* '' */
.icon-arrow-slideshow:before { content: '\e803'; } /* '' */
.icon-clock:before { content: '\e804'; } /* '' */
.icon-close:before { content: '\e805'; } /* '' */
.icon-mail:before { content: '\e806'; } /* '' */
.icon-phone:before { content: '\e807'; } /* '' */






.f-numbers {
    font-size: rem(50);
    font-weight: 700;
    @include breakpoint($tablet) {
        font-weight: 900;
        font-size: rem(70);
    }
    @include breakpoint($desktop) {
        font-size: rem(80);
    }
}

.f-h1 {
    font-weight: 700;
    font-size: rem(30);
    @include breakpoint($tablet) {
        font-size: rem(50);
    }
    @include breakpoint($desktop) {
        font-size: rem(60);
    }
}

.f-h2 {
    font-weight: 700;
    font-size: rem(40);
    @include breakpoint($desktop) {
        font-size: rem(50);
    }
}

.f-h3 {
    font-weight: 700;
    font-size: rem(30);
    @include breakpoint($tablet) {
        font-size: rem(40);
    }
    &--small {
        font-weight: 700;
        font-size: rem(20);
        @include breakpoint($tablet) {
            font-size: rem(30);
        }
    }
}

.f-date {
    font-size: rem(18);
    font-weight: 400;
    display: block;
}

.f-details {
    font-size: rem(14);
    font-weight: 400;
}
